<template>
	<div />
</template>

<script>
import { mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('auth.logout')
		}
	},
	created() {
		this.logout().then(() => {
			this.$router.push({ name: 'Landing' })
		})
	},
	methods: {
		...mapActions('auth', ['logout'])
	}
}
</script>
